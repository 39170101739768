import { BrowserRouter } from "react-router-dom";
import { Suspense, useEffect, useRef, useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import { ThemeCustomization } from "./Theme";
import "./App.css";

import { ApolloProvider } from "@apollo/client";
import subDomainConfig from "./config/subDomainConfig";
import setup from "./GraphQL/setup";
import Components from "./Components";
import { constantData } from "./Assets/constants";
import User from "./SubDomains/User";
import { element } from "prop-types";

const App = () => {
  const [currentSubDomain, setCurrentSubDomain] = useState();
  const renderPage = useRef(true);

  const [snackbarDetails, setSnackbarDetails] = useState({
    openSnackbar: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    const handleApolloError = (event) => {
      console.log("handleApolloError()");
      if(event.detail && snackbarDetails.openSnackbar)
      setSnackbarDetails({
        openSnackbar: true,
        severity: constantData.SEVERITY.ERROR,
        message: event.detail,
      });
     };

    window.addEventListener("apollo-error", handleApolloError);

    // Cleanup listener on component unmount
    return () => {
      console.log("handleApolloError() 1");
      window.removeEventListener("apollo-error", handleApolloError);
    };
  }, []);


  const getDomain = () => {
    let host = window.location.host;
    const extractedSubDomain = host.split(".").slice(0, -1).join(""); //Split will be changed, when the Domain will be implemeted.

    if (extractedSubDomain) {
      return extractedSubDomain;
    }
    return "www";
  };

  if (renderPage.current) {
    const response = getDomain();
    let matchedDomain = subDomainConfig.find(
      (value) => response === value.subDomain
    );
    setCurrentSubDomain(matchedDomain);
    renderPage.current = false;
  }

  // const [mode, setMode] = useState(() => {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     return "dark";
  //   } else {
  //     return "light";
  //   }
  // });
  return (
    <>
      <BrowserRouter>
        <ApolloProvider client={setup}>
          <ThemeCustomization themeMode={"light"}>
            <Paper
              sx={(theme) => ({
                height: theme.dimensions.dimen100vh,
                overflow: theme.display.overFlow.overFlowAuto,
              })}
            >
              <Suspense
                fallback={
                  <Box
                    sx={(theme) => ({
                      display: theme.display.displayFlex,
                      justifyContent: theme.alignment.justifyContent.center,
                      alignItems: theme.alignment.alignItems.center,
                      height: theme.dimensions.dimen100vh,
                    })}
                  >
                    <CircularProgress thickness={2} size={90} />
                  </Box>
                }
              >
                <Suspense
                  fallback={
                    <Box
                      sx={(theme) => ({
                        mt: theme.dimensions.minus.dimen80,
                      })}
                    >
                      <LinearProgress />
                    </Box>
                  }
                >
                  <User />
                  {/* {currentSubDomain && currentSubDomain.component} */}
                </Suspense>
              </Suspense>
              <Components.CustomSnackbar
                openSnackbar={snackbarDetails.openSnackbar}
                message={snackbarDetails.message}
                severity={snackbarDetails.severity}
                setOpenSnackbar={setSnackbarDetails}
              />
            </Paper>
          </ThemeCustomization>
        </ApolloProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
